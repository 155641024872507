import {create} from 'zustand'
import Editor from "~/fc/Editor";
import {CanvasEventEmitter, SelectMode, SelectOneType} from "~/fc/CanvasEventEmitter";

type CanvasStore = {
    mSelectMode: string,
    mSelectOneType: string,
    mSelectId: string,
    mSelectIds: [],
    mSelectActive: [],

    canvasEditor: Editor | any;
    event: CanvasEventEmitter | null;
    canvas: fabric.Canvas | null;
    setCanvasEditor: (editor: Editor) => void;
    setEvent: (eventEmitter: CanvasEventEmitter) => void;
    setCanvas: (canvas: fabric.Canvas) => void;

    selectOne: (e: any) => void;
    selectMulti: (e: any) => void;
    selectCancel: () => void;
};

export const useCanvasStore = create<CanvasStore>((set) => ({
    mSelectMode: SelectMode.EMPTY,
    mSelectOneType: SelectOneType.EMPTY,
    mSelectId: '',
    mSelectIds: [],
    mSelectActive: [],

    canvasEditor: null,
    event: null,
    canvas: null,
    setCanvasEditor: (editor) => set({canvasEditor: editor}),
    setEvent: (eventEmitter) => set({event: eventEmitter}),
    setCanvas: (canvas) => set({canvas: canvas}),
    selectOne: (e) => set({
        mSelectMode: SelectMode.ONE,
        mSelectId: e[0].id,
        mSelectOneType: e[0].type,
        mSelectIds: e.map((item: fabric.Object) => item.id),
    }),
    selectMulti: (e) => set({
        mSelectMode: SelectMode.MULTI,
        mSelectId: '',
        mSelectIds: e.map((item: fabric.Object) => item.id),
    }),
    selectCancel: () => set({
        mSelectId: '',
        mSelectIds: [],
        mSelectMode: SelectMode.EMPTY,
        mSelectOneType: SelectOneType.EMPTY,
    }),
}));
