import {fabric} from 'fabric';
import Editor from '~/fc/Editor';
import {debounce, throttle} from 'lodash-es';

type IEditor = Editor;


class WorkspacePlugin {
    public canvas: fabric.Canvas;
    public editor: IEditor;
    static pluginName = 'WorkspacePlugin';
    static events = ['sizeChange'];
    static apis = ['big', 'small', 'auto', 'one', 'setSize'];
    // @ts-ignore
    workspaceEl: HTMLElement;
    // @ts-ignore
    workspace: null | fabric.Rect;
    option: any;

    constructor(canvas: fabric.Canvas, editor: IEditor) {
        this.canvas = canvas;
        this.editor = editor;
        this.init({
            width: 900,
            height: 2000,
        });
    }

    init(option: any) {
        const workspaceEl = document.querySelector('#workspace') as HTMLElement;
        if (!workspaceEl) {
            throw new Error('element #workspace is missing, plz check!');
        }
        this.workspaceEl = workspaceEl;
        this.workspace = null;
        this.option = option;
        this._initBackground();
        this._initWorkspace();
        this._initResizeObserve();
        this._bindWheel();
    }

    hookImportAfter() {
        return new Promise((resolve) => {
            const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
            if (workspace) {
                workspace.set('selectable', false);
                workspace.set('hasControls', false);
                this.setSize(workspace.width || 1920, workspace.height || 1080);
                this.editor.emit('sizeChange', workspace.width, workspace.height);
            }
            resolve(true);
        });
    }

    hookSaveAfter() {
        return new Promise((resolve) => {
            this.auto();
            resolve(true);
        });
    }

    _initBackground() {
        this.canvas.backgroundImage = '';
        this.canvas.setWidth(this.workspaceEl.offsetWidth);
        this.canvas.setHeight(this.workspaceEl.offsetHeight);
    }

    _initWorkspace() {
        const {width, height} = this.option;
        const workspace = new fabric.Rect({
            fill: 'rgba(255,255,255,1)',
            width,
            height,
            id: 'workspace',
        });
        workspace.set('selectable', false);
        workspace.set('hasControls', false);
        workspace.hoverCursor = 'default';
        this.canvas.add(workspace);
        this.canvas.renderAll();

        this.workspace = workspace;
        this.auto();
    }

    setCenterFromObject(obj: fabric.Rect) {
        const {canvas} = this;
        const objCenter = obj.getCenterPoint();
        const viewportTransform = canvas.viewportTransform;
        if (canvas.width === undefined || canvas.height === undefined || !viewportTransform) return;
        viewportTransform[4] = canvas.width / 2 - objCenter.x * viewportTransform[0];
        viewportTransform[5] = canvas.height / 2 - objCenter.y * viewportTransform[3];
        canvas.setViewportTransform(viewportTransform);
        canvas.renderAll();
    }

    _initResizeObserve() {
        const resizeObserver = new ResizeObserver(debounce(() => {
                throttle(() => {
                    this.auto();
                }, 50)
            }, 200)
        );
        resizeObserver.observe(this.workspaceEl);
    }

    setSize(width: number, height: number) {
        this._initBackground();
        this.option.width = width;
        this.option.height = height;
        this.workspace = this.canvas
            .getObjects()
            .find((item) => item.id === 'workspace') as fabric.Rect;
        this.workspace.set('width', width);
        this.workspace.set('height', height);
        this.auto();
    }

    setZoomAuto(scale: number, cb?: (left?: number, top?: number) => void) {
        const {workspaceEl} = this;
        const width = workspaceEl.offsetWidth;
        const height = workspaceEl.offsetHeight;
        this.canvas.setWidth(width);
        this.canvas.setHeight(height);
        const center = this.canvas.getCenter();
        this.canvas.setViewportTransform(fabric.iMatrix.concat());
        this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), scale);
        if (!this.workspace) return;
        this.setCenterFromObject(this.workspace);

        this.workspace.clone((cloned: fabric.Rect) => {
            this.canvas.clipPath = cloned;
            this.canvas.requestRenderAll();
        });
        if (cb) cb(this.workspace.left, this.workspace.top);
    }

    _getScale() {
        const viewPortWidth = this.workspaceEl.offsetWidth;
        const viewPortHeight = this.workspaceEl.offsetHeight;
        if (viewPortWidth / viewPortHeight < this.option.width / this.option.height) {
            return viewPortWidth / this.option.width;
        }
        return viewPortHeight / this.option.height;
    }

    big() {
        let zoomRatio = this.canvas.getZoom();
        zoomRatio += 0.05;
        const center = this.canvas.getCenter();
        this.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    }

    small() {
        let zoomRatio = this.canvas.getZoom();
        zoomRatio -= 0.05;
        const center = this.canvas.getCenter();
        this.canvas.zoomToPoint(
            new fabric.Point(center.left, center.top),
            zoomRatio < 0 ? 0.01 : zoomRatio
        );
    }

    auto() {
        const scale = this._getScale();
        this.setZoomAuto(scale - 0.08);
    }

    one() {
        this.setZoomAuto(0.8 - 0.08);
        this.canvas.requestRenderAll();
    }

    _bindWheel() {
        this.canvas.on('mouse:wheel', function (this: fabric.Canvas, opt) {
            const delta = opt.e.deltaY;
            let zoom = this.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            const center = this.getCenter();
            this.zoomToPoint(new fabric.Point(center.left, center.top), zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });
    }

    destroy() {
        console.log('pluginDestroy');
    }

}

export default WorkspacePlugin;
