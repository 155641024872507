import {fabric} from 'fabric';
import Editor from '~/fc/Editor';

type IEditor = Editor;

class HistoryPlugin {
    public canvas: fabric.Canvas;
    public editor: IEditor;
    static pluginName = 'HistoryPlugin';
    static apis = ['undo', 'redo', 'getHistory'];
    static events = ['historyInitSuccess'];
    public hotkeys: string[] = ['ctrl+z'];


    private undoStack: string[] = [];
    private redoStack: string[] = [];

    constructor(canvas: fabric.Canvas, editor: IEditor) {
        this.canvas = canvas;
        this.editor = editor;

        this._init();
    }

    _init() {
        this.canvas.on({
            'object:added': (event: any) => this._save(event),
            'object:modified': (event: any) => this._save(event),
            'selection:updated': (event: any) => this._save(event),
        });
    }

    getHistory() {
        return {
          undoStack: this.undoStack,
          redoStack: this.redoStack,
        };
    }

    _save(event: any) {
        const isSelect = event.action === undefined && event.e;
        if (isSelect || !this.canvas) return;
        const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
        if (!workspace) {
            return;
        }

        // @ts-ignore
        this.undoStack.push(this.editor.getJson());
    }

    undo() {
        if (this.undoStack.length > 1) {
            const currentState = this.undoStack.pop();
            this.redoStack.push(currentState as any);
            this.renderCanvas();
        }
    }

    redo() {
        if (this.redoStack.length) {
            const redoState = this.redoStack.pop();
            this.undoStack.push(redoState as any);
            this.renderCanvas();
        }
    }


    renderCanvas = () => {
        this.canvas.clear();
        const state = this.undoStack[this.undoStack.length - 1] || "{}";

        this.canvas.loadFromJSON(state, () => {
            this.canvas.renderAll();
        });
    };

    hotkeyEvent(eventName: string, e: any) {
        if (eventName === 'ctrl+z' && e.type === 'keydown') {
            this.undo();
        }
    }

    destroy() {
        console.log('pluginDestroy');
    }
}

export default HistoryPlugin;
