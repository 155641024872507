// @ts-nocheck
import React, {useState} from 'react';
import {fabric} from 'fabric';
import {Tabs, Button, Checkbox, Radio, Slider, ColorPicker} from "antd";
import {useCanvasStore} from "~/store/canvasStore";


function Filters() {
  const [paramsFilters, setParamsFilters] = useState({
    'Brightness': {
      status: false,
      params: [
        {
          key: 'brightness',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Contrast': {
      status: false,
      params: [
        {
          key: 'contrast',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Saturation': {
      status: false,
      params: [
        {
          key: 'saturation',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Vibrance': {
      status: false,
      params: [
        {
          key: 'vibrance',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'HueRotation': {
      status: false,
      params: [
        {
          key: 'rotation',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Noise': {
      status: false,
      params: [
        {
          key: 'noise',
          value: 0,
          uiType: 'number',
          min: -1,
          max: 1000,
          step: 0.1,
        },
      ],
    },
    'Pixelate': {
      status: false,
      params: [
        {
          key: 'blocksize',
          value: 0.01,
          uiType: 'number',
          min: 0.01,
          max: 100,
          step: 0.01,
        },
      ],
    },
    'Blur': {
      status: false,
      params: [
        {
          key: 'blur',
          value: 0,
          uiType: 'number',
          min: 0,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Grayscale': {
      status: false,
      params: [
        {
          key: 'mode',
          value: 'average',
          uiType: 'select',
          list: ['average', 'lightness', 'luminosity'],
        },
      ],
    },
    'RemoveColor': {
      status: false,
      params: [
        {
          key: 'color',
          value: '',
          uiType: 'color',
        },
        {
          key: 'distance',
          value: 0,
          uiType: 'number',
          min: 0,
          max: 1,
          step: 0.01,
        },
      ],
    },
    'Gamma': {
      status: false,
      params: [
        {
          key: 'red',
          value: 0,
          uiType: 'number',
          min: 0.01,
          max: 2.2,
          step: 0.01,
        },
        {
          key: 'green',
          value: 0,
          uiType: 'number',
          min: 0.01,
          max: 2.2,
          step: 0.01,
        },
        {
          key: 'blue',
          value: 0,
          uiType: 'number',
          min: 0.01,
          max: 2.2,
          step: 0.01,
        },
      ],
      handler(red: number | string, green: number | string, blue: number | string) {
        return {
          gamma: [red, green, blue],
        };
      },
    },
  });

  const [noParamFilters, setNoParamFilters] = useState({
    "BlackWhite": false,
    "Brownie": false,
    "Vintage": false,
    "Kodachrome": false,
    "technicolor": false,
    "Polaroid": false,
    "Invert": false,
    "Sepia": false,
  })

  const {canvasEditor, mSelectMode, mSelectOneType} = useCanvasStore();
  if (mSelectMode !== 'one' || mSelectOneType !== 'image') {
    return <></>
  }

  function _getFabricFilterType(type: string) {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  function _getFilter(sourceImg: any, type: string) {
    let imgFilter = null;

    if (sourceImg) {
      const fabricType = _getFabricFilterType(type);
      const {length} = sourceImg.filters;
      let item, i;

      for (i = 0; i < length; i += 1) {
        item = sourceImg.filters[i];
        if (item.type === fabricType) {
          imgFilter = item;
          break;
        }
      }
    }

    return imgFilter;
  }

  function _createFilter(sourceImg: any, type: string, options = null) {
    let filterObj;
    // capitalize first letter for matching with fabric image filter name
    const fabricType = _getFabricFilterType(type);
    // @ts-ignore
    const ImageFilter = fabric.Image.filters[fabricType];
    if (ImageFilter) {
      filterObj = new ImageFilter(options);
      filterObj.options = options;
      sourceImg.filters.push(filterObj);
    }
    sourceImg.applyFilters();
    canvasEditor.canvas.renderAll();
    return filterObj;
  }

  function _removeFilter(sourceImg: any, type: string) {
    const fabricType = _getFabricFilterType(type);
    sourceImg.filters = sourceImg.filters.filter((value: fabric.Object) => value.type !== fabricType);
    sourceImg.applyFilters();
    canvasEditor.canvas.renderAll();
  }


  const changeFiltersByParams = (type: string) => {
    const activeObject = canvasEditor.canvas.getActiveObjects()[0];
    // @ts-ignore
    let mInfo = paramsFilters[type]
    const moduleInfo = {...mInfo, type}
    if (moduleInfo.status) {
      if (moduleInfo.handler) {
        _changeAttrByHandler(moduleInfo);
      } else {
        moduleInfo.params.forEach((paramsItem: any) => {
          _changeAttr(type, paramsItem.key, paramsItem.value);
        });
      }
    } else {
      _removeFilter(activeObject, type);
    }
  };

  function _changeAttrByHandler(moduleInfo: any) {
    const activeObject = canvasEditor.canvas.getActiveObjects()[0];
    _removeFilter(activeObject, moduleInfo.type);
    const params = moduleInfo.params.map((item: any) => item.value);
    _createFilter(activeObject, moduleInfo.type, moduleInfo.handler(...params));
  }

  function _changeAttr(type: string, key: string, value: any) {
    const activeObject = canvasEditor.canvas.getActiveObjects()[0];
    const itemFilter = _getFilter(activeObject, type);
    if (itemFilter) {
      itemFilter[key] = value;
    } else {
      const imgFilter = _createFilter(activeObject, type);
      imgFilter[key] = value;
    }
    activeObject.applyFilters();
    canvasEditor.canvas.renderAll();
  }

  return (
    <div>
      <Tabs defaultActiveKey="Effects" items={[
        {
          key: 'Effects',
          label: 'Effects',
          children: <div>
            {Object.keys(noParamFilters).map(item => {
              // @ts-ignore
              return (<Button type={noParamFilters[item] ? 'primary' : 'dashed'}
                              onClick={() => {
                                const activeObject = canvasEditor.canvas.getActiveObjects()[0];
                                // @ts-ignore
                                if (!noParamFilters[item]) {
                                  const itemFilter = _getFilter(activeObject, item);
                                  if (!itemFilter) {
                                    _createFilter(activeObject, item);
                                  }
                                } else {
                                  _removeFilter(activeObject, item);
                                }

                                setNoParamFilters({
                                  ...noParamFilters,
                                  // @ts-ignore
                                  [item]: !noParamFilters[item]
                                })

                              }}>{item}</Button>)

            })}
          </div>
        },
        {
          key: 'Filters',
          label: 'Filters',
          children:
            <>
              <div>
                {Object.keys(paramsFilters).map(item => {
                  return (
                    <>
                      {/* @ts-ignore*/}
                      <Button type={paramsFilters[item]['status'] ? 'primary' : 'dashed'}
                              onClick={() => {
                                setParamsFilters(prevState => ({
                                  ...prevState,
                                  [item]: {
                                    // @ts-ignore
                                    ...prevState[item],
                                    // @ts-ignore
                                    status: !prevState[item]['status']
                                  }
                                }));

                                setTimeout(() => changeFiltersByParams(item), 10)
                              }}>
                        {item}
                      </Button>

                      {
                        paramsFilters[item]['status'] &&
                        paramsFilters[item]['params'].map(prm => (
                          prm['uiType'] === 'select' ?
                            <Radio.Group value={prm['value']} onChange={(e) => {
                              const newValue = e.target.value;
                              setParamsFilters(prevState => {
                                const newState = {...prevState};
                                const filter = newState[item];
                                const param = filter.params.find(p => p.key === prm['key']);
                                if (param) {
                                  param.value = newValue;
                                }
                                return newState;
                              });
                            }}>
                              {prm['list'].map(listItem =>
                                <Radio key={listItem} value={listItem}>
                                  {listItem}
                                </Radio>
                              )}
                            </Radio.Group>
                            : prm['uiType'] === 'number' ? <>
                              <Slider
                                value={prm['value']}
                                max={prm['max']}
                                min={prm['min']}
                                step={prm['step']}
                                onChange={(value) => {
                                  setParamsFilters(prevState => ({
                                    ...prevState,
                                    [item]: {
                                      ...prevState[item],
                                      params: prevState[item].params.map(p =>
                                        p.key === prm.key ? {...p, value: value} : p
                                      )
                                    }
                                  }));
                                  changeFiltersByParams(item)
                                }}
                              />
                            </> : prm['uiType'] === 'color' ? <ColorPicker
                              value={prm['value']}
                              onChange={(clr) => {
                                setParamsFilters(prevState => ({
                                  ...prevState,
                                  [item]: {
                                    ...prevState[item],
                                    params: prevState[item].params.map(p =>
                                      p.key === prm.key ? {...p, value: clr.toHex()} : p
                                    )
                                  }
                                }));
                                changeFiltersByParams(item)
                              }}
                            /> : null
                        ))
                      }
                    </>)
                })}
              </div>
            </>
        },
      ]}/>
    </div>
  );
}

export default Filters;
