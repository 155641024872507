export function selectFiles(options: {
    accept?: string;
    capture?: string;
    multiple?: boolean;
}): Promise<FileList | null> {
    return new Promise((resolve) => {
        // Create an input element
        const input = document.createElement('input');
        input.type = 'file';
        if (options.accept) input.accept = options.accept;
        if (options.capture) input.capture = options.capture;
        if (options.multiple) input.multiple = options.multiple;

        // Handle file selection
        input.onchange = (event: Event) => {
            const target = event.target as HTMLInputElement;
            resolve(target.files);
            // Clean up after resolving
            document.body.removeChild(input);
        };

        // Append to body to make it clickable, then click it programmatically
        document.body.appendChild(input);
        input.click();
    });
}


export const getPolygonVertices = (edges: number, radius: number) => {
    const vertices = [];
    const interiorAngle = (Math.PI * 2) / edges;
    let rotationAdjustment = -Math.PI / 2;
    if (edges % 2 === 0) {
        rotationAdjustment += interiorAngle / 2;
    }
    for (let i = 0; i < edges; i++) {
        const rad = i * interiorAngle + rotationAdjustment;
        vertices.push({
            x: Math.cos(rad) * radius,
            y: Math.sin(rad) * radius,
        });
    }
    return vertices;
};


export function getImgStr(file: File | Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(new Error("Error reading file"));
        };

        reader.readAsDataURL(file);
    });
}


export function insertImgFile(str: string) {
    return new Promise((resolve) => {
        const imgEl = document.createElement('img');
        imgEl.src = str;
        document.body.appendChild(imgEl);
        imgEl.onload = () => {
            resolve(imgEl);
        };
    });
}
