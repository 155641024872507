import React, {useEffect, useRef, useState} from 'react';
import {useCanvasStore} from "~/store/canvasStore";
import {useEditorStore} from "~/store/editorStore";
import {Modal, ColorPicker} from 'antd';
import {Button} from '~/components/ui/button'

function Toolbar() {
  const {canvasEditor} = useCanvasStore();
  const {ruler, dragging, toggleRuler, toggleDragging} = useEditorStore();
  // const [{redoStack, undoStack }, setHistory] = useState()

  useEffect(() => {
    if(canvasEditor) {
    console.log("=>(Toolbar.tsx:13) canvasEditor.getHistory()", canvasEditor.getHistory());
      // setHistory(canvasEditor.getHistory());
    }
  }, [canvasEditor]);

  return (
    <div>
      <Button
        onClick={toggleRuler}
        variant="outline"
      >
        {ruler ? 'Disable' : 'Enable'} Ruler
      </Button>
      <Button
        onClick={toggleDragging}
        variant="outline"
      >
        {dragging ? 'Disable' : 'Enable'} Paning
      </Button>

      <Button
        onClick={() => {
          canvasEditor.big();
        }}
        variant="outline"
      >
        + Zoom In
      </Button>
      <Button
        onClick={() => {
          canvasEditor.small();
        }}
        variant="outline"
      >
        - Zoom Out
      </Button>
      <Button
        onClick={() => {
          canvasEditor.one();
        }}
        variant="outline"
      >
        Actual Size
      </Button>
      <Button
        onClick={() => {
          canvasEditor.auto();
        }}
        variant="outline"
      >
        Fit To Canvas
      </Button>
      <Button
        onClick={() => {
          canvasEditor.insert();
        }}
        variant="outline"
      >
        Import JSON
      </Button>
      <Button
        onClick={() => {
          canvasEditor.insertImage();
        }}
        variant="outline"
      >
        Add Image/SVG
      </Button>
      <Button
        onClick={() => {
          canvasEditor.undo();
        }}
        variant="outline"
      >
        Undo (undoStack.length)
      </Button>
      <Button
        onClick={() => {
          canvasEditor.redo();
        }}
        variant="outline"
      >
        Redo (redoStack.length)
      </Button>
      <Button
        onClick={() => {
          canvasEditor.preview().then((dataUrl: string) => {
            Modal.info({
              title: <></>,
              content: <img
                style={{
                  width: '100%',
                  height: 'auto'
                }}
                alt={"Design Preview"}
                src={dataUrl}
              />,
            })
          });
        }}
        variant="outline"
      >
        Preview
      </Button>

      <Button
        onClick={() => {
          canvasEditor.clear();
        }}
        variant="outline"
      >
        Clear All
      </Button>

      <Button
        onClick={() => {
          canvasEditor.saveImg();
        }}
        variant="outline"
      >
        Download Image
      </Button>

      <Button
        onClick={() => {
          canvasEditor.saveJson();
        }}
        variant="outline"
      >
        Download Source
      </Button>
      <Button
        onClick={() => {
          canvasEditor.setSize(1920, 1080);
          canvasEditor.on('sizeChange', (width: number, height: number) => {
            console.log("-> height", height);
            console.log("-> width", width);
          });
        }}
        variant="outline"
      >
        Resize To 1920x1080
      </Button>
      <Button
        onClick={() => {
          canvasEditor.setSize(1080, 1920);
          canvasEditor.on('sizeChange', (width: number, height: number) => {
            console.log("-> height", height);
            console.log("-> width", width);
          });
        }}
        variant="outline"
      >
        Resize To 1080x1920
      </Button>


      <Button>
        <ColorPicker
          size="small"
          defaultValue={"#fff"}
          onChange={(color) => {
            const workspace = canvasEditor.canvas.getObjects().find((item: fabric.Object) => item.id === 'workspace');
            workspace.set('fill', color.toHexString());
            canvasEditor.canvas.renderAll();
          }} allowClear showText={(color) => <span>Background Color: ({color.toHexString()})</span>}/>
      </Button>

    </div>
  );
}

export default Toolbar;
