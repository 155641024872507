import {v4 as uuid} from 'uuid';
import {fabric} from 'fabric';

import Editor from '~/fc/Editor';
import {selectFiles} from "~/fc/utils";

type IEditor = Editor;


function downFile(fileStr: string, fileType: string) {
  const anchorEl = document.createElement('a');
  anchorEl.href = fileStr;
  anchorEl.download = `${uuid()}.${fileType}`;
  document.body.appendChild(anchorEl); // required for firefox
  anchorEl.click();
  anchorEl.remove();
}

class ServersPlugin {
  public canvas: fabric.Canvas;
  public editor: IEditor;

  static pluginName = 'ServersPlugin';

  static apis = [
    'insert',
    'insertImage',
    'insertSvgFile',
    'getJson',
    'dragAddItem',
    'clipboard',
    'saveJson',
    'saveSvg',
    'saveImg',
    'clear',
    'preview',
  ];

  static events = [];
  static hotkeys = [];

  constructor(canvas: fabric.Canvas, editor: IEditor) {
    this.canvas = canvas;
    this.editor = editor;
  }

  insert() {
    selectFiles({accept: '.json'}).then((files) => {
      if (files && files.length > 0) {
        const [file] = files;
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = () => {
          this.insertSvgFile(reader.result);
        };
      }
    });
  }

  insertImage() {
    // Use the selectFiles function to pick an image or SVG
    selectFiles({accept: 'image/*, .svg'}).then((files) => {
      if (files && files.length > 0) {
        const [file] = files;

        // Read the file as a Data URL
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const dataURL = reader.result as string;

          if (file.type === "image/svg+xml") {
            // If it's an SVG, use loadSVGFromURL
            fabric.loadSVGFromURL(dataURL, (objects, options) => {
              const svg = fabric.util.groupSVGElements(objects, options);
              this.canvas.add(svg).renderAll();
            });
          } else {
            // If it's an image, use Image.fromURL
            fabric.Image.fromURL(dataURL, (image) => {
              this.canvas.add(image).renderAll();
            });
          }
        };
      }
    });
  };

  insertSvgFile(jsonFile: any) {
    // this.editor.hooksEntity.hookImportBefore.callAsync(jsonFile, () => {
    this.canvas.loadFromJSON(jsonFile, () => {
      this.canvas.renderAll();
      this.editor.hooksEntity.hookImportAfter.callAsync(jsonFile, () => {
        this.canvas.renderAll();
      });
    });
    // });
  }

  getJson() {
    return this.canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']);
  }

  dragAddItem(event: DragEvent, item: fabric.Object) {
    const {left, top} = this.canvas.getSelectionElement().getBoundingClientRect();
    if (event.x < left || event.y < top || item.width === undefined) return;

    const point = {
      x: event.x - left,
      y: event.y - top,
    };
    const pointerVpt = this.canvas.restorePointerVpt(point);
    item.left = pointerVpt.x - item.width / 2;
    item.top = pointerVpt.y;
    this.canvas.add(item);
    this.canvas.requestRenderAll();
  }

  clipboard() {
    // const jsonStr = this.getJson();
    // clipboardText(JSON.stringify(jsonStr, null, '\t'));
  }

  saveJson() {
    const dataUrl = this.getJson();
    const fileStr = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(dataUrl, null, '\t')
    )}`;
    downFile(fileStr, 'json');
  }

  saveSvg() {
    this.editor.hooksEntity.hookSaveBefore.callAsync('', () => {
      const option = this._getSaveSvgOption();
      const dataUrl = this.canvas.toSVG(option as any);
      const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(dataUrl)}`;
      this.editor.hooksEntity.hookSaveAfter.callAsync(fileStr, () => {
        downFile(fileStr, 'svg');
      });
    });
  }

  saveImg() {
    this.editor.hooksEntity.hookSaveBefore.callAsync('', () => {
      const option = this._getSaveOption();
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      const dataUrl = this.canvas.toDataURL(option);
      this.editor.hooksEntity.hookSaveAfter.callAsync(dataUrl, () => {
        downFile(dataUrl, 'png');
      });
    });
  }

  preview() {
    return new Promise((resolve, reject) => {
      this.editor.hooksEntity.hookSaveBefore.callAsync('', () => {
        const option = this._getSaveOption();
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        this.canvas.renderAll();
        const dataUrl = this.canvas.toDataURL(option);
        this.editor.hooksEntity.hookSaveAfter.callAsync(dataUrl, () => {
          resolve(dataUrl);
        });
      });
    });
  }

  _getSaveSvgOption() {
    const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
    const {left, top, width, height} = workspace as fabric.Object;
    return {
      width,
      height,
      viewBox: {
        x: left,
        y: top,
        width,
        height,
      },
    };
  }

  _getSaveOption() {
    const workspace = this.canvas
      .getObjects()
      .find((item: fabric.Object) => item.id === 'workspace');
    const {left, top, width, height} = workspace as fabric.Object;
    const option = {
      name: 'New Image',
      format: 'png',
      quality: 1,
      width,
      height,
      left,
      top,
    };
    return option;
  }

  clear() {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.id !== 'workspace') {
        this.canvas.remove(obj);
      }
    });
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  destroy() {
    console.log('pluginDestroy');
  }
}

export default ServersPlugin;
