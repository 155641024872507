import {fabric} from 'fabric';

import type Editor from '~/fc/Editor';
import event, {SelectEvent, SelectMode} from "~/fc/CanvasEventEmitter";

export default class FlipPlugin {
    public canvas: fabric.Canvas;
    public editor: Editor;
    static pluginName = 'FlipPlugin';
    static apis = ['flip'];
    selectedMode: any;

    constructor(canvas: fabric.Canvas, editor: Editor) {
        this.canvas = canvas;
        this.editor = editor;
        this.selectedMode = SelectMode.EMPTY;

        this.init();
    }

    init() {
        event.on(SelectEvent.ONE, () => (this.selectedMode.value = SelectMode.ONE));
        event.on(SelectEvent.MULTI, () => (this.selectedMode.value = SelectMode.MULTI));
        event.on(SelectEvent.CANCEL, () => (this.selectedMode.value = SelectMode.EMPTY));
    }

    flip(type: 'X' | 'Y') {
        const activeObject = this.canvas.getActiveObject();
        if (activeObject) {
            activeObject.set(`flip${type}`, !activeObject[`flip${type}`]).setCoords();
            this.canvas.requestRenderAll();
        }
    }

    contextMenu() {
        if (this.selectedMode.value === SelectMode.ONE) {
            return [
                {
                    text: 'flip',
                    hotkey: '❯',
                    subitems: [
                        {
                            text: 'flip x',
                            hotkey: '|',
                            onclick: () => this.flip('X'),
                        },
                        {
                            text: 'flip.y',
                            hotkey: '-',
                            onclick: () => this.flip('Y'),
                        },
                    ],
                },
            ];
        }
    }

    destroy() {
        console.log('pluginDestroy');
    }
}
