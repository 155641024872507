import React from 'react';
import {Tabs} from 'antd';
import Templates from "~/components/fc/content-tab/Templates";
import Elements from "~/components/fc/content-tab/Elements";

function ContentTab() {
    return (
        <div>
            <Tabs
                defaultActiveKey="Templates"
                type="card"
                items={[
                    {
                        label: `Templates`,
                        key: `Templates`,
                        children: <Templates/>,
                    },
                    {
                        label: `Elements`,
                        key: `Elements`,
                        children: <Elements/>,
                    },


                ]}
            />
        </div>
    );
}

export default ContentTab;
