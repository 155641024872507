import {fabric} from 'fabric';
import Editor from '~/fc/Editor';

type IEditor = Editor;

class GroupAlignPlugin {
    public canvas: fabric.Canvas;
    public editor: IEditor;
    static pluginName = 'GroupAlignPlugin';
    static apis = ['left', 'right', 'xcenter', 'ycenter', 'top', 'bottom', 'xequation', 'yequation'];

    // public hotkeys: string[] = ['space'];
    constructor(canvas: fabric.Canvas, editor: IEditor) {
        this.canvas = canvas;
        this.editor = editor;
    }

    left() {
        const {canvas} = this;

        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {left} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                left: left - bounding.left + item.left,
            });
            item.setCoords();
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    right() {
        const {canvas} = this;

        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {left, width} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                left: left + width - (bounding.left + bounding.width) + item.left,
            });
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    xcenter() {
        const {canvas} = this;
        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {left, width} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                left: left + width / 2 - (bounding.left + bounding.width / 2) + item.left,
            });
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    ycenter() {
        const {canvas} = this;
        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {top, height} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                top: top + height / 2 - (bounding.top + bounding.height / 2) + item.top,
            });
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    top() {
        const {canvas} = this;

        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {top} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                top: top - bounding.top + item.top,
            });
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    bottom() {
        const {canvas} = this;
        const activeObject = canvas.getActiveObject();
        const selectObjects = canvas.getActiveObjects();
        // @ts-ignore
        const {top, height} = activeObject;
        canvas.discardActiveObject();
        selectObjects.forEach((item) => {
            const bounding = item.getBoundingRect(true);
            item.set({
                // @ts-ignore
                top: top + height - (bounding.top + bounding.height) + item.top,
            });
        });
        const activeSelection = new fabric.ActiveSelection(selectObjects, {
            canvas: canvas,
        });
        canvas.setActiveObject(activeSelection);
        canvas.requestRenderAll();
    }

    xequation() {
        const {canvas} = this;
        const activeObject = canvas.getActiveObject();

        function getItemWidth(item: any) {
            return item.aCoords.tr.x - item.aCoords.tl.x;
        }

        function getAllItemHeight() {
            let count = 0;
            // @ts-ignore
            activeObject.forEachObject((item) => {
                count += getItemWidth(item);
            });
            return count;
        }

        function spacWidth() {
            const count = getAllItemHeight();
            // @ts-ignore
            const allSpac = activeObject.width - count;
            // @ts-ignore
            return allSpac / (activeObject._objects.length - 1);
        }

        function getItemLeft(i: any) {
            if (i === 0) return 0;
            let width = 0;
            for (let index = 0; index < i; index++) {
                // @ts-ignore
                width += getItemWidth(activeObject._objects[index]);
            }
            return width;
        }

        if (activeObject && activeObject.type === 'activeSelection') {
            const activeSelection = activeObject;
            // @ts-ignorev
            activeSelection._objects.sort((a, b) => a.left - b.left);

            const itemSpac = spacWidth();
            // @ts-ignore
            const yHeight = activeObject.width / 2;
            // @ts-ignore
            activeObject.forEachObject((item, i) => {
                const preHeight = getItemLeft(i);
                const top = itemSpac * i + preHeight - yHeight;
                item.set('left', top);
            });
            canvas.renderAll();
        }
    }

    yequation() {
        const {canvas} = this;
        const activeObject = canvas.getActiveObject();

        function getItemHeight(item: any) {
            return item.aCoords.bl.y - item.aCoords.tl.y;
        }

        function getAllItemHeight() {
            let count = 0;
            // @ts-ignore
            activeObject.forEachObject((item) => {
                count += getItemHeight(item);
            });
            return count;
        }

        function spacHeight() {
            const count = getAllItemHeight();
            // @ts-ignore
            const allSpac = activeObject.height - count;
            // @ts-ignore
            return allSpac / (activeObject._objects.length - 1);
        }

        function getItemTop(i: any) {
            if (i === 0) return 0;
            let height = 0;
            for (let index = 0; index < i; index++) {
                // @ts-ignore
                height += getItemHeight(activeObject._objects[index]);
            }
            return height;
        }

        if (activeObject && activeObject.type === 'activeSelection') {
            const activeSelection = activeObject;
            // @ts-ignore
            activeSelection._objects.sort((a, b) => a.top - b.top);

            const itemSpac = spacHeight();
            // @ts-ignore
            const yHeight = activeObject.height / 2;
            // @ts-ignore
            activeObject.forEachObject((item, i) => {
                const preHeight = getItemTop(i);
                const top = itemSpac * i + preHeight - yHeight;
                item.set('top', top);
            });
            canvas.renderAll();
        }
    }

    destroy() {
        console.log('pluginDestroy');
    }
}

export default GroupAlignPlugin;
