import React from 'react';
import { Tabs } from 'antd';
import Attributes from "~/components/fc/attr-tab/Attributes";
import Filters from './Filters';

function AttrTab() {
    return (
        <div>
            <Tabs
                defaultActiveKey="Attributes"
                type="card"
                items={[
                    {
                        label: `Attributes`,
                        key: `Attributes`,
                        children: <Attributes />,
                    },
                    {
                        label: `Filters`,
                        key: `Filters`,
                        children: <Filters />,
                    },

                ]}
            />
        </div>
    );
}

export default AttrTab;
