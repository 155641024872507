import React from 'react';
import { useCanvasStore } from "~/store/canvasStore";
import { Button } from "antd";
import { getImgStr, insertImgFile, selectFiles } from "~/fc/utils";

function ReplaceImage() {
    const { canvasEditor, mSelectMode, mSelectOneType } = useCanvasStore();
    if (mSelectMode !== 'one' || mSelectOneType !== 'image') {
        return <></>
    }

    const replaceImage = async () => {
        const activeObject = canvasEditor.canvas.getActiveObjects()[0];
        if (activeObject && activeObject.type === 'image') {
            // @ts-ignore
            const [file] = await selectFiles({ accept: 'image/*', multiple: false });
            const fileStr = await getImgStr(file);
            // @ts-ignore
            const imgEl = await insertImgFile(fileStr);
            const width = activeObject.get('width');
            const height = activeObject.get('height');
            const scaleX = activeObject.get('scaleX');
            const scaleY = activeObject.get('scaleY');
            // @ts-ignore
            activeObject.setSrc(imgEl.src, () => {
                // @ts-ignore
                activeObject.set('scaleX', (width * scaleX) / imgEl.width);
                // @ts-ignore
                activeObject.set('scaleY', (height * scaleY) / imgEl.height);
                canvasEditor.canvas.renderAll();
            });
            // @ts-ignore
            imgEl.remove();
        }
    };
    return (
        <Button onClick={replaceImage}>
            Replace Image
        </Button>
    );
}

export default ReplaceImage;
