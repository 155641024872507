import React from 'react';
import Group from "~/components/fc/attr-tab/Group";
import ReplaceImage from "~/components/fc/attr-tab/ReplaceImage";

function Attributes() {
    return (
        <div>
            <Group/>
            <ReplaceImage/>
        </div>
    );
}

export default Attributes;
