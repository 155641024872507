import EventEmitter from 'events';
import {fabric} from 'fabric';
import {Canvas} from 'fabric/fabric-impl';

export enum SelectMode {
    EMPTY = '',
    ONE = 'one',
    MULTI = 'multiple',
}

export enum SelectOneType {
    EMPTY = '',
    GROUP = 'group',
    POLYGON = 'polygon',
}

export enum SelectEvent {
    ONE = 'selectOne',
    MULTI = 'selectMultiple',
    CANCEL = 'selectCancel',
}

class CanvasEventEmitter extends EventEmitter {
    handler: Canvas | undefined;
    mSelectMode = '';

    init(handler: CanvasEventEmitter['handler']) {
        this.handler = handler;
        if (this.handler) {
            this.handler.on('selection:created', () => this.selected());
            this.handler.on('selection:updated', () => this.selected());
            this.handler.on('selection:cleared', () => this.selected());
        }
    }

    private selected() {
        if (!this.handler) {
            throw TypeError('Not initialized yet');
        }

        const actives = this.handler
            .getActiveObjects()
        // .filter((item) => !(item instanceof fabric.GuideLine));
        if (actives && actives.length === 1) {
            this.emit(SelectEvent.ONE, actives);
        } else if (actives && actives.length > 1) {
            this.mSelectMode = 'multiple';
            this.emit(SelectEvent.MULTI, actives);
        } else {
            this.emit(SelectEvent.CANCEL);
        }
    }
}

export default new CanvasEventEmitter();
export {CanvasEventEmitter};
