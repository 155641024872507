import "~/styles/globals.css";
import React from 'react'
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import FC from "~/fc";
import Toolbar from "~/components/Toolbar";
import ContentTab from "~/components/fc/content-tab";
import AttrTab from "~/components/fc/attr-tab";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Toolbar/>
      <ContentTab/>
      <AttrTab/>
      <FC/>
    </BrowserRouter>
  </React.StrictMode>
);
