import React, {useEffect, useRef} from 'react';
import {useCanvasStore} from "~/store/canvasStore";
import {useEditorStore} from "~/store/editorStore";
import axios from "axios";

function Templates() {
    const {canvasEditor} = useCanvasStore();
    const {materialTypelist, setMaterialTypelist} = useEditorStore();
    const fetchedTemplates = useRef(false);
    useEffect(() => {
        if (canvasEditor && !fetchedTemplates.current) {
            canvasEditor.getMaterialType('template').then((list: any) => {
                setMaterialTypelist(list);
                fetchedTemplates.current = true;
            });
        }
    }, [canvasEditor]);

    const getTempData = (tmplUrl: string) => {
        const getTemp = axios.get(tmplUrl);
        getTemp.then((res) => {
            canvasEditor.insertSvgFile(JSON.stringify(res.data));
        });
    };

    return (
        <div>
            <div style={{display: 'flex'}}>
                {materialTypelist.map((item, index) => (
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}} key={item.value}>
                        {/*<div>{item.label}</div>*/}

                        {item.list.map((info: any, i: number) => (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                                 key={`${i}-bai1-button`}>
                                {/*<span>{info.label}</span>*/}
                                <img
                                    style={{width: 100, height: 200, marginLeft: 10}}
                                    alt={info.label}
                                    src={info.src}
                                    onClick={() => getTempData(info.tempUrl)}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>

        </div>
    );
}

export default Templates;
