import React from 'react';
import {fabric} from 'fabric';
import {useCanvasStore} from "~/store/canvasStore";
import {Button} from '~/components/ui/button'
import {v4 as uuid} from 'uuid';
import {getPolygonVertices} from "~/fc/utils";

function Elements() {
  const {canvasEditor} = useCanvasStore();
  return (
    <div>
      <Button onClick={() => {
        const text = new fabric.IText('Double click to edit Text', {
          shadow: '',
          fontFamily: 'arial',
          fontSize: 80,
          id: uuid(),
        });
        canvasEditor.canvas.add(text);
        text.center();
        canvasEditor.canvas.setActiveObject(text);
      }}
              variant="outline"
      >
        Text
      </Button>
      <Button onClick={() => {
        const text = new fabric.Textbox('Double click to edit Text box', {
          shadow: '',
          fontFamily: 'arial',
          splitByGrapheme: true,
          width: 400,
          fontSize: 80,
          id: uuid(),
        });
        canvasEditor.canvas.add(text);
        text.center();
        canvasEditor.canvas.setActiveObject(text);
      }}
              variant="outline"
      >
        Text Box
      </Button>
      <Button onClick={() => {
        const rect = new fabric.Rect({
          shadow: '',
          fill: '#F57274',
          width: 400,
          height: 400,
          id: uuid(),
          name: 'Rect',
        });
        canvasEditor.canvas.add(rect);
        rect.center();
        canvasEditor.canvas.setActiveObject(rect);
      }}
              variant="outline"
      >
        Rect
      </Button>

      <Button onClick={() => {
        const circle = new fabric.Circle({
          shadow: '',
          radius: 150,
          fill: '#57606B',
          id: uuid(),
          name: 'Circle',
        });
        canvasEditor.canvas.add(circle);
        circle.center();
        canvasEditor.canvas.setActiveObject(circle);
      }}
              variant="outline"
      >
        Circle
      </Button>
      <Button onClick={() => {
        const triangle = new fabric.Triangle({
          shadow: '',
          width: 400,
          height: 400,
          fill: '#92706B',
          id: uuid(),
          name: 'Triangle',
        });
        canvasEditor.canvas.add(triangle);
        triangle.center();
        canvasEditor.canvas.setActiveObject(triangle);
      }}
              variant="outline"
      >
        Triangle
      </Button>
      <Button onClick={() => {
        const polygon = new fabric.Polygon(getPolygonVertices(5, 200), {
          shadow: '',
          fill: '#ccc',
          id: uuid(),
          name: 'Polygon',
        });
        polygon.set({
          width: 400,
          height: 400,
        });
        canvasEditor.canvas.add(polygon);
        polygon.center();
        canvasEditor.canvas.setActiveObject(polygon);
      }}
              variant="outline"
      >
        Polygon
      </Button>
      <Button onClick={() => {
        canvasEditor.setMode(true);
        canvasEditor.setArrow(false);

        canvasEditor.canvas.forEachObject((obj: fabric.Object) => {
          if (obj.id !== 'workspace') {
            obj.selectable = false;
            obj.evented = false;
          }
        });
      }}
              variant="outline"
      >
        Start Line Drawing
      </Button>
      <Button onClick={() => {
        canvasEditor.setMode(true);
        canvasEditor.setArrow(true);

        canvasEditor.canvas.forEachObject((obj: fabric.Object) => {
          if (obj.id !== 'workspace') {
            obj.selectable = false;
            obj.evented = false;
          }
        });
      }}
              variant="outline"
      >
        Start Arrow Drawing
      </Button>

    </div>
  );
}

export default Elements;
