import React, {useLayoutEffect, useRef} from 'react';
import {fabric} from "fabric";
import {CanvasEventEmitter, SelectEvent} from '~/fc/CanvasEventEmitter';
import Editor from '~/fc/Editor';
import DringPlugin from '~/fc/plugins/DringPlugin';
import AlignGuidLinePlugin from '~/fc/plugins/AlignGuidLinePlugin';
import ControlsPlugin from '~/fc/plugins/ControlsPlugin';
import ControlsRotatePlugin from '~/fc/plugins/ControlsRotatePlugin';
import CenterAlignPlugin from '~/fc/plugins/CenterAlignPlugin';
import LayerPlugin from '~/fc/plugins/LayerPlugin';
import CopyPlugin from '~/fc/plugins/CopyPlugin';
import MoveHotKeyPlugin from '~/fc/plugins/MoveHotKeyPlugin';
import DeleteHotKeyPlugin from '~/fc/plugins/DeleteHotKeyPlugin';
import GroupPlugin from '~/fc/plugins/GroupPlugin';
import DrawLinePlugin from '~/fc/plugins/DrawLinePlugin';
import GroupTextEditorPlugin from '~/fc/plugins/GroupTextEditorPlugin';
import GroupAlignPlugin from '~/fc/plugins/GroupAlignPlugin';
import WorkspacePlugin from '~/fc/plugins/WorkspacePlugin';
import DownFontPlugin from '~/fc/plugins/DownFontPlugin';
import HistoryPlugin from '~/fc/plugins/HistoryPlugin';
import FlipPlugin from "~/fc/plugins/FlipPlugin";
import RulerPlugin from "~/fc/plugins/RulerPlugin";
import MaterialPlugin from "~/fc/plugins/MaterialPlugin";
import {useCanvasStore} from "~/store/canvasStore";

function FC() {
  const hasInitialized = useRef(false);

  const {
    setCanvasEditor,
    setEvent,
    setCanvas,
    selectOne,
    selectMulti,
    selectCancel
  } = useCanvasStore();

  useLayoutEffect(() => {
    if (!hasInitialized.current) {
      const editorInstance = new Editor();
      // Create the event emitter instance
      const eventInstance = new CanvasEventEmitter();
      // Initialize the canvas
      const canvasInstance = new fabric.Canvas('canvas', {
        fireRightClick: true,
        stopContextMenu: true,
        controlsAboveOverlay: true,
      });

      // Mark as initialized
      hasInitialized.current = true;

      editorInstance.init(canvasInstance);
      // @ts-ignore
      editorInstance.use(DringPlugin);
      // @ts-ignore
      editorInstance.use(AlignGuidLinePlugin);
      // @ts-ignore
      editorInstance.use(ControlsPlugin);
      // @ts-ignore
      editorInstance.use(ControlsRotatePlugin);
      // @ts-ignore
      editorInstance.use(CenterAlignPlugin);
      // @ts-ignore
      editorInstance.use(LayerPlugin);
      // @ts-ignore
      editorInstance.use(CopyPlugin);
      // @ts-ignore
      editorInstance.use(MoveHotKeyPlugin);
      // @ts-ignore
      editorInstance.use(DeleteHotKeyPlugin);
      // @ts-ignore
      editorInstance.use(GroupPlugin);
      // @ts-ignore
      editorInstance.use(DrawLinePlugin);
      // @ts-ignore
      editorInstance.use(GroupTextEditorPlugin);
      // @ts-ignore
      editorInstance.use(GroupAlignPlugin);
      // @ts-ignore
      editorInstance.use(WorkspacePlugin);
      // @ts-ignore
      editorInstance.use(DownFontPlugin);
      // @ts-ignore
      editorInstance.use(HistoryPlugin);
      // @ts-ignore
      editorInstance.use(FlipPlugin);
      // @ts-ignore
      editorInstance.use(RulerPlugin);
      // @ts-ignore
      editorInstance.use(MaterialPlugin);

      eventInstance.init(canvasInstance);

      eventInstance.on(SelectEvent.ONE, selectOne);
      eventInstance.on(SelectEvent.MULTI, selectMulti);
      eventInstance.on(SelectEvent.CANCEL, selectCancel);

      setCanvasEditor(editorInstance);
      setEvent(eventInstance);
      setCanvas(canvasInstance);
    }
  }, []);


  return (
    <div id="workspace">
      <canvas id="canvas"></canvas>
    </div>
  );
}

export default FC;
