import {fabric} from 'fabric';
import {v4 as uuid} from 'uuid';
import Editor from '~/fc/Editor';

type IEditor = Editor;

class CopyPlugin {
    public canvas: fabric.Canvas;
    public editor: IEditor;
    static pluginName = 'CopyPlugin';
    static apis = ['clone'];
    public hotkeys: string[] = ['ctrl+v', 'ctrl+c'];
    private cache: null | fabric.ActiveSelection | fabric.Object;

    constructor(canvas: fabric.Canvas, editor: IEditor) {
        this.canvas = canvas;
        this.editor = editor;
        this.cache = null;
    }

    // Copy multiple selected objects
    _copyActiveSelection(activeObject: fabric.Object) {
        // Spacing settings
        const grid = 10;
        const canvas = this.canvas;
        activeObject?.clone((cloned: fabric.Object) => {
            //Clone again to handle the selection of multiple objects
            cloned.clone((clonedObj: fabric.ActiveSelection) => {
                canvas.discardActiveObject();
                if (clonedObj.left === undefined || clonedObj.top === undefined) return;
                // Reassign the cloned canvas
                clonedObj.canvas = canvas;
                // Set location
                clonedObj.set({
                    left: clonedObj.left + grid,
                    top: clonedObj.top + grid,
                    evented: true,
                    id: uuid(),
                });
                clonedObj.forEachObject((obj: fabric.Object) => {
                    obj.id = uuid();
                    canvas.add(obj);
                });
                // Solve non-choice problems
                clonedObj.setCoords();
                canvas.setActiveObject(clonedObj);
                canvas.requestRenderAll();
            });
        });
    }

    // Single object copy
    _copyObject(activeObject: fabric.Object) {
        // Spacing settings
        const grid = 10;
        const canvas = this.canvas;
        activeObject?.clone((cloned: fabric.Object) => {
            if (cloned.left === undefined || cloned.top === undefined) return;
            canvas.discardActiveObject();
            // Set location
            cloned.set({
                left: cloned.left + grid,
                top: cloned.top + grid,
                evented: true,
                id: uuid(),
            });
            canvas.add(cloned);
            canvas.setActiveObject(cloned);
            canvas.requestRenderAll();
        });
    }

    // Copy element
    clone(paramsActiveObeject?: fabric.ActiveSelection | fabric.Object) {
        const activeObject = paramsActiveObeject || this.canvas.getActiveObject();
        if (!activeObject) return;
        if (activeObject?.type === 'activeSelection') {
            this._copyActiveSelection(activeObject);
        } else {
            this._copyObject(activeObject);
        }
    }

    // Shortcut key extension callback
    hotkeyEvent(eventName: string, e: any) {
        if (eventName === 'ctrl+c' && e.type === 'keydown') {
            const activeObject = this.canvas.getActiveObject();
            this.cache = activeObject;
        }
        if (eventName === 'ctrl+v' && e.type === 'keydown') {
            if (this.cache) {
                this.clone(this.cache);
            }
        }
    }

    contextMenu() {
        const activeObject = this.canvas.getActiveObject();
        if (activeObject) {
            return [{text: 'copy', hotkey: 'Ctrl+V', disabled: false, onclick: () => this.clone()}];
        }
    }

    destroy() {
        console.log('pluginDestroy');
    }
}

export default CopyPlugin;
