import EventEmitter from 'events';
import hotkeys from 'hotkeys-js';
import {AsyncSeriesHook} from 'tapable';
import {fabric} from "fabric";

import ServersPlugin from './plugins/ServersPlugin';

class Editor extends EventEmitter {
    // @ts-ignore
    canvas: fabric.Canvas;
    contextMenu: any;

    private pluginMap: {
        [propName: string]: IPluginTempl;
    } = {};

    private customEvents: string[] = [];
    private customApis: string[] = [];

    private hooks: IEditorHooksType[] = [
        'hookImportBefore',
        'hookImportAfter',
        'hookSaveBefore',
        'hookSaveAfter',
    ];

    hooksEntity: {
        [propName: string]: AsyncSeriesHook<any>;
    } = {};

    init(canvas: fabric.Canvas) {
        this.canvas = canvas;
        // this._initContextMenu();
        // this._bindContextMenu();
        this._initActionHooks();
        this._initServersPlugin();
    }

    use(plugin: IPluginClass, options: IPluginOption = {}) {
        if (this._checkPlugin(plugin)) {
            this._saveCustomAttr(plugin);
            const pluginRunTime = new plugin(this.canvas, this, options);
            this.pluginMap[plugin.pluginName] = pluginRunTime;
            this._bindingHooks(pluginRunTime);
            this._bindingHotkeys(pluginRunTime);
            this._bindingApis(pluginRunTime);
        }
    }

    getPlugin(name: string) {
        if (this.pluginMap[name]) {
            return this.pluginMap[name];
        }
    }

    private _checkPlugin(plugin: IPluginClass) {
        const {pluginName, events = [], apis = []} = plugin;
        if (this.pluginMap[pluginName]) {
            throw new Error(pluginName + 'Plug-in repeated initialization');
        }
        events.forEach((eventName: string) => {
            if (this.customEvents.find((info) => info === eventName)) {
                throw new Error(pluginName + 'in plug-in' + eventName + 'repeat');
            }
        });

        apis.forEach((apiName: string) => {
            if (this.customApis.find((info) => info === apiName)) {
                throw new Error(pluginName + 'Plug-in Plug-in' + apiName + 'repeat');
            }
        });
        return true;
    }

    private _bindingHooks(plugin: IPluginTempl) {
        this.hooks.forEach((hookName) => {
            const hook = plugin[hookName];
            if (hook) {
                this.hooksEntity[hookName].tapPromise(plugin.pluginName + hookName, function () {
                    return hook.apply(plugin, [...arguments]);
                });
            }
        });
    }

    private _bindingHotkeys(plugin: IPluginTempl) {
        plugin?.hotkeys?.forEach((keyName: string) => {
            // Support keyup
            hotkeys(keyName, {keyup: true}, (e) => plugin.hotkeyEvent(keyName, e));
        });
    }

    private _saveCustomAttr(plugin: IPluginClass) {
        const {events = [], apis = []} = plugin;
        this.customApis = this.customApis.concat(apis);
        this.customEvents = this.customEvents.concat(events);
    }

    private _bindingApis(pluginRunTime: IPluginTempl) {
        // @ts-ignore
        const {apis = []} = pluginRunTime.constructor;
        apis.forEach((apiName: string) => {
            (this as any)[apiName] = function () {
                return pluginRunTime[apiName].apply(pluginRunTime, [...arguments]);
            };
        });
    }

    // private _bindContextMenu() {
    //     this.canvas.on('mouse:down', (opt) => {
    //         if (opt.button === 3) {
    //             let menu: IPluginMenu[] = [];
    //             Object.keys(this.pluginMap).forEach((pluginName) => {
    //                 const pluginRunTime = this.pluginMap[pluginName];
    //                 const pluginMenu = pluginRunTime.contextMenu && pluginRunTime.contextMenu();
    //                 if (pluginMenu) {
    //                     menu = menu.concat(pluginMenu);
    //                 }
    //             });
    //             this._renderMenu(opt, menu);
    //         }
    //     });
    // }

    // private _renderMenu(opt: fabric.IEvent, menu: IPluginMenu[]) {
    //     if (menu.length !== 0) {
    //         this.contextMenu.hideAll();
    //         this.contextMenu.setData(menu);
    //         this.contextMenu.show(opt.e.clientX, opt.e.clientY);
    //     }
    // }

    _initActionHooks() {
        this.hooks.forEach((hookName) => {
            this.hooksEntity[hookName] = new AsyncSeriesHook(['data']);
        });
    }

    //
    // _initContextMenu() {
    //     this.contextMenu = new ContextMenu(this.canvas.wrapperEl, []);
    //     this.contextMenu.install();
    // }
    //
    //
    _initServersPlugin() {
        // @ts-ignore
        this.use(ServersPlugin, {});
    }

}


export default Editor;
