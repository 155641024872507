import {fabric} from 'fabric';
import FontFaceObserver from 'fontfaceobserver';
import Editor from '~/fc/Editor';

type IEditor = Editor;

interface Font {
    type: string;
    fontFamily: string;
}

function downFontByJSON(str: string) {
    const skipFonts = ['arial', 'Microsoft YaHei'];
    const fontFamilies: string[] = JSON.parse(str)
        .objects.filter(
            (item: Font) =>
                // is text and does not contain fonts
                item.type.includes('text') && !skipFonts.includes(item.fontFamily)
        )
        .map((item: Font) => item.fontFamily);
    const fontFamiliesAll = fontFamilies.map((fontName) => {
        const font = new FontFaceObserver(fontName);
        return font.load(null, 150000);
    });
    return Promise.all(fontFamiliesAll);
}

class DownFontPlugin {
    public canvas: fabric.Canvas;
    public editor: IEditor;
    static pluginName = 'DownFontPlugin';

    constructor(canvas: fabric.Canvas, editor: IEditor) {
        this.canvas = canvas;
        this.editor = editor;
    }

    hookImportBefore(json: any) {
        // console.log(downFontByJSON(json).then, 111);
        return downFontByJSON(json);
    }

    destroy() {
        console.log('pluginDestroy');
    }
}

export default DownFontPlugin;
