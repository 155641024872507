import {Button} from '~/components/ui/button'
import React from 'react';
import {useCanvasStore} from "~/store/canvasStore";

function Group() {
  const {canvasEditor, mSelectMode, mSelectOneType} = useCanvasStore();
  let isGroup = mSelectMode === 'one' && mSelectOneType === 'group';
  let isMultiple = mSelectMode === 'multiple';
  if (!(isMultiple || isGroup)) {
    return <></>
  }
  return (
    <Button onClick={() => {
      if (isMultiple) {
        canvasEditor.group();
      } else {
        canvasEditor.unGroup();
      }
    }}
            variant="outline"
    >
      {isGroup ? 'UnGroup' : 'Group'}
    </Button>
  );
}

export default Group;
