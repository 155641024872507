import { create } from 'zustand'
import {useCanvasStore} from "~/store/canvasStore";

type EditorStore = {
    ruler: boolean;
    dragging: boolean;
    materialTypelist: any[];
    toggleRuler: () => void;
    toggleDragging: () => void;
    setMaterialTypelist: (materialTypelist: any[]) => void;
};

export const useEditorStore = create<EditorStore>((set) => ({
    ruler: false,
    dragging: false,
    materialTypelist: [],
    toggleRuler: () => {
        set((state) => {
            const newRulerState = !state.ruler;

            if (newRulerState) {
                useCanvasStore.getState().canvasEditor?.rulerEnable();
            } else {
                useCanvasStore.getState().canvasEditor?.rulerDisable();
            }

            return {ruler: newRulerState};
        });
    },
    toggleDragging: () => {
        set((state) => {
            const newDraggingState = !state.dragging;

            if (newDraggingState) {
                useCanvasStore.getState().canvasEditor?.startDring();
            } else {
                useCanvasStore.getState().canvasEditor?.endDring();
            }

            return {dragging: newDraggingState};
        });
    },
    setMaterialTypelist: (materialTypelist) => set({materialTypelist})
}));
